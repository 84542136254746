import { IAlert, IAlertTakeProfit } from "@/interfaces/Alert";
import { AlertAverageType, AlertMargin, AlertQuantity, AlertStoplossType, DcaType, DcaUpdateType, CandlePriceType } from "@/enums/Alert";

export const DefaultAlert: IAlert = {
    id: 0,
    name: "New Alert",
    toggle: false,
    settings: {
        ticker: "BTCUSDT",
        quantityType: AlertQuantity.Dynamic,
        quantity: 1,
        leverage: 20,
        margin: AlertMargin.ISOLATED,
        stopLoss: {
            toggle: true,
            type: AlertStoplossType.Percent,
            value: 1
        },

        trailing: {
            toggle: false,
            activate: 1,
            stopLoss: 1
        },

        takeProfits: {
            toggle: false,
            list: []
        },

        dca: {
            enabled: false,
            type: DcaType.Market,
            quantity: 100,
            amountFactor: 1,
            updater: DcaUpdateType.off,
            density: 1,
            ordersAmount: 5,
            orderCancel: -1,
            firstPrice: 0,
            lastPrice: 0,
            percentDistance: 1,
            candlePrice: CandlePriceType.close,
        }
    }
};

export const DefaultAlertTakeProfit: IAlertTakeProfit = {
    value: 1,
    quantity: 100,
    breakEven: {
        toggle: false,
        type: 0
    }
};