import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExchangesBar = _resolveComponent("ExchangesBar")!
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_Constructor = _resolveComponent("Constructor")!
  const _component_Settings = _resolveComponent("Settings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ExchangesBar),
    _createVNode(_component_Alerts, {
      list: _ctx.alertList,
      ref: "alert_list",
      "onUpdate:current": _ctx.setCurrentAlert,
      "onUpdate:add": _ctx.addAlert
    }, null, 8, ["list", "onUpdate:current", "onUpdate:add"]),
    (_ctx.visibleAlert)
      ? (_openBlock(), _createBlock(_component_Constructor, {
          key: 0,
          alert: _ctx.visibleAlert,
          "onUpdate:save": _ctx.saveAlert,
          "onUpdate:remove": _ctx.removeAlert
        }, null, 8, ["alert", "onUpdate:save", "onUpdate:remove"]))
      : _createCommentVNode("", true),
    (_ctx.visibleAlert)
      ? (_openBlock(), _createBlock(_component_Settings, {
          key: 1,
          alert: _ctx.visibleAlert
        }, null, 8, ["alert"]))
      : _createCommentVNode("", true)
  ], 64))
}