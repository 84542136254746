
import { Options, Vue } from "vue-class-component";
import { IAlert } from "@/interfaces/Alert";
import { AlertService } from "@/services/AlertService";

@Options({
    props: {
        list: Array
    }
})
export default class AlertsList extends Vue {
    private list: IAlert[];

    private currentAlert = 0;

    private setActiveAlert(alertId: number, toggle: boolean): void {
        AlertService.setActiveAlert(alertId, toggle);
    }

    public setCurrentAlert(alertId: number): void {
        this.currentAlert = alertId;

        this.$emit("update:current", alertId);
    }

    private addAlert(): void {
        this.$emit("update:add");
    }
}
