import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c7578f84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alerts" }
const _hoisted_2 = { class: "alerts__header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "alerts__alert-name" }
const _hoisted_5 = ["onUpdate:modelValue", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "alerts__header-add",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addAlert && _ctx.addAlert(...args)))
      }, "Add")
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, ({ name, id, toggle }, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "alerts__alert",
        key: i,
        style: _normalizeStyle(_ctx.currentAlert === id ? {
             borderColor: 'rgb(47, 47, 47)',
             background: 'rgb(55, 55, 55)',
             'border-left': '0.75vh solid rgb(30, 60, 60)'
         } : {}),
        onClick: ($event: any) => (_ctx.setCurrentAlert(id))
      }, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(name), 1),
        _withDirectives(_createElementVNode("input", {
          class: "alerts__alert-enable",
          type: "checkbox",
          "onUpdate:modelValue": ($event: any) => ((_ctx.list[i].toggle) = $event),
          onChange: ($event: any) => (_ctx.setActiveAlert(id, toggle))
        }, null, 40, _hoisted_5), [
          [_vModelCheckbox, _ctx.list[i].toggle]
        ])
      ], 12, _hoisted_3))
    }), 128))
  ]))
}