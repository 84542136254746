
import { Vue } from "vue-class-component";
import { Exchanges } from "@/enums/Exchanges";
export default class ExchangesBar extends Vue {
    private activeExchange: Exchanges = Exchanges.BinanceFutures;

    private exchanges: Record<Exchanges, { name: string, icon: string }> = {
        [Exchanges.BinanceFutures]: {
            name: "Binance Futures",
            icon: "binance.svg"
        }
    };

    private setExchange(exchange: string): void {
        this.activeExchange = parseInt(exchange);
    }
}
