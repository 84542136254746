
import { Options, Vue } from "vue-class-component";
import ExchangesBar from "./components/ExchangesBar.vue";
import Alerts from "@/components/Alerts.vue";
import { IAlert } from "@/interfaces/Alert";
import Constructor from "@/components/Constructor.vue";
import { AlertService } from "@/services/AlertService";
import Settings from "@/components/Settings.vue";
import { DefaultAlert } from "@/defaults/Alert";
import { BackendConfig } from "@/config/Backend";

@Options({
    components: {
        Settings,
        Constructor,
        Alerts,
        ExchangesBar,
    },
})
export default class App extends Vue {
    private alertList: IAlert[] = [];

    private currentAlert = 0;
    public visibleAlert: IAlert = this.alertList[0];

    private setCurrentAlert(alertId: number): void {
        this.visibleAlert = this.alertList.find(({ id }) => alertId === id);
        this.currentAlert = this.visibleAlert?.id ?? 0;
        this.$forceUpdate();
    }

    private saveAlert(): void {
        AlertService.saveAlert(this.visibleAlert.id, this.visibleAlert);
        this.$forceUpdate();
    }

    private async addAlert(): Promise<void> {
        const backendResponse = await AlertService.addAlert();

        if (backendResponse.type !== "ok") return;

        const result: IAlert = {
            ...DefaultAlert,
            settings: {
                ...DefaultAlert.settings,
            },
            id: Math.max(-1, ...this.alertList.map((e) => e.id)) + 1,
        };

        this.alertList.push(result);

        if (!this.visibleAlert) {
            this.visibleAlert = this.alertList[this.alertList.length - 1];
            this.currentAlert = this.visibleAlert.id;
        }

        this.$forceUpdate();
    }

    private removeAlert(): void {
        if (!this.visibleAlert) return;

        const idx = this.alertList.findIndex(({ id }) => this.visibleAlert.id === id);
        const alertId = this.visibleAlert.id;

        if (idx !== -1) {
            this.alertList.splice(idx, 1);
            this.visibleAlert = this.alertList[0];
            this.currentAlert = this.visibleAlert?.id ?? 0;

            (this.$refs.alert_list as Alerts).setCurrentAlert(this.currentAlert);
        }

        AlertService.removeAlert(alertId);

        this.$forceUpdate();
    }

    public async mounted(): Promise<void> {
        let token = this.$cookies.get("token");

        if (!token) token = window.location.href
            .replace(BackendConfig.FrontUrl, "")
            .replace("?token=", "");

        history.pushState("", "", "/");

        AlertService.token = token;

        this.$cookies.set("token", AlertService.token);

        const alerts = await AlertService.getAll();

        if (!alerts.data) {
            this.$cookies.remove("token");
            return;
        }

        this.alertList = alerts.data;

        if (this.alertList.length > 0) {
            this.currentAlert = this.alertList[0].id;
            this.visibleAlert = this.alertList[0];
        }

        this.$forceUpdate();
    }
}
