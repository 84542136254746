export enum AlertQuantity {
    Dynamic,
    Fixed
}

export enum AlertMargin {
    CROSSED,
    ISOLATED
}

export enum AlertStoplossType {
    Percent,
    Fixed
}

export enum AlertAverageType {
    Order,
    Grid
}

export enum DcaType {
    Market,
    Limit,
}

export enum CandlePriceType {
    "open",
    "high",
    "low",
    "close",
    "hl2",
    "hlc3",
    "ohlc4",
    "hlcc4",
}

export enum DcaUpdateType {
    "off",
    "1m",
    "3m",
    "5m",
    "15m",
    "30m",
    "1h",
    "4h",
    "1d",
}