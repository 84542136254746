
import { Options, Vue } from "vue-class-component";
import { SettingsPages } from "@/enums/SettingsPages";
import { IAlert } from "@/interfaces/Alert";
import { AlertAverageType, AlertMargin, AlertQuantity, AlertStoplossType, DcaType, CandlePriceType, DcaUpdateType } from "@/enums/Alert";
import { DefaultAlertTakeProfit } from "@/defaults/Alert";

@Options({
    computed: {
        DcaType() { return DcaType; },
        DcaUpdateType() { return DcaUpdateType; },
        CandlePriceType() { return CandlePriceType; },
        AlertAverageType() { return AlertAverageType; },
        AlertStoplossType() { return AlertStoplossType; },
        AlertMargin() { return AlertMargin; },
        AlertQuantity() { return AlertQuantity; },
        SettingsPages() { return SettingsPages; }
    },
    props: {
        alert: Object
    }
})
export default class AlertSettings extends Vue {
    private page: SettingsPages = SettingsPages.Open;
    private alert: IAlert;

    private buttonSelectedStyle(btn: SettingsPages): Record<string, string> {
        return this.page === btn ? {
            background: "rgb(50, 50, 50)"
        } : {};
    }

    private addTakeProfit(): void {
        this.alert.settings.takeProfits.list.push({ ...DefaultAlertTakeProfit, breakEven: { ...DefaultAlertTakeProfit.breakEven } });

        this.$forceUpdate();
    }

    private removeTakeProfit(i: number): void {
        this.alert.settings.takeProfits.list.splice(i, 1);

        this.$forceUpdate();
    }
}
