import { IAlert } from "@/interfaces/Alert";
import Axios from "axios";
import { BackendConfig } from "@/config/Backend";
import { IAddAlert, IBackendResponse } from "@/interfaces/BackendResponse";
import { DefaultAlert } from "@/defaults/Alert";

class AlertServiceClass {
    public token = "";

    public setActiveAlert(alertId: number, toggle: boolean): Promise<IBackendResponse<unknown>> {
        return this.post("alert/toggle", { id: alertId, toggle });
    }

    public saveAlert(alertId: number, alert: IAlert): Promise<IBackendResponse<unknown>> {
        return this.post("alert/save", { id: alertId, alert });
    }

    public addAlert(): Promise<IAddAlert> {
        return this.post("alert/add", { alert: DefaultAlert });
    }

    public removeAlert(alertId: number): Promise<IBackendResponse<unknown>> {
        return this.post("alert/remove", { id: alertId });
    }

    public getAll(): Promise<IBackendResponse<IAlert[]>> {
        return this.post("alert/get", {});
    }

    private async post<T>(path: string, data: Record<string, unknown>): Promise<IBackendResponse<T>> {
        return (await Axios.post(BackendConfig.Url + path, { token: this.token, ...data })).data;
    }
}

export const AlertService = new AlertServiceClass();