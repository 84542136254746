
import { Options, Vue } from "vue-class-component";
import { IAlert } from "@/interfaces/Alert";
import { AlertService } from "@/services/AlertService";

@Options({
    props: {
        alert: Object
    } as unknown as {
        alert: IAlert
    }
})
export default class Constructor extends Vue {
    private alert: IAlert;

    private get webhookUrl(): string {
        return "https://shinobi.candlestorm.io/alert/hook";
    }

    private get dataJson(): string {
        const data = {
            id: this.alert.id,
            side: true,
            token: AlertService.token
        };

        return JSON.stringify(data, null, "\t");
    }

    private async copyWebhook(): Promise<void> {
        const element = this.$refs.webhook as { value: string };

        await navigator.clipboard.writeText(element.value);
    }
}
